import { type Readable, readonly, writable } from 'svelte/store';
import { browser } from '$app/environment';

export enum Breakpoint {
	sm = '(max-width: 640px)',
	md = '(max-width: 768px)',
	lg = '(max-width: 1024px)',
	xl = '(max-width: 1280px)',
	'2xl' = '(max-width: 1536px)'
}

const _listeners = new Map<Breakpoint, Readable<boolean>>();

export const createBreakpointStore = (breakpoint: Breakpoint = Breakpoint.md) => {
	const alreadyThere = _listeners.get(breakpoint);
	if (alreadyThere) {
		return alreadyThere;
	}

	const evaluateMatches = () => {
		matches.set(window.matchMedia(breakpoint).matches.valueOf());
	};

	const matches = writable(false, () => {
		if (!browser) {
			return;
		}

		window.addEventListener('resize', evaluateMatches);

		return () => {
			window.removeEventListener('resize', evaluateMatches);
		};
	});

	if (browser) {
		evaluateMatches();
	}

	const store = readonly(matches);
	_listeners.set(breakpoint, store);

	return store;
};
